<template>
    <div class="slider-con report-slider-con">
        <div class="search-con">
            <el-input
                size="small"
                placeholder="请输入内容"
                suffix-icon="el-icon-search"
            >
            </el-input>
        </div>
        <div class="tree-con">
            <el-tree
                :data="treeData"
                :props="defaultProps"
                node-key="id"
                :default-expanded-keys="defaultKey"
                :current-node-key="currentNodeKey"
                ref="tree"
                :highlight-current="true"
                @node-click="handleNodeClick"
                @node-collapse="handleNodeCollapse"
            >
                <span class="slot-t-node" slot-scope="{ node, data }">
                    <div v-if="data.datatype===3&&data.children.length<=0" class="leaf-node">
                        <template v-if="data.report_state===-1">
                            <i class="iconfont iconlingxing blue"></i>
                            <span>{{ data.name }}</span>
                            <span class="blue status">当前月</span>
                        </template>
                        <template v-if="data.report_state===0||data.report_state===null">
                            <i class="iconfont iconlingxing red"></i>
                            <span>{{ data.name }}</span>
                            <span class="red status">未报</span>
                        </template>
                        <template v-if="data.report_state===1">
                            <i class="iconfont iconlingxing green"></i>
                            <span>{{ data.name }}</span>
                            <span class="green status">已报未审</span>
                        </template>
                        <template v-if="data.report_state===2">
                            <i class="iconfont iconlingxing green"></i>
                            <span>{{ data.name }}</span>
                            <span class="green status">退回</span>
                        </template>
                        <template v-if="data.report_state===3">
                            <i class="iconfont iconlingxing green"></i>
                            <span>{{ data.name }}</span>
                            <span class="green status">已审</span>
                        </template>
                    </div>
                    <div class="parent-node" v-else>
                        <i v-if="node.expanded" class="iconfont iconshouqi" @click.stop="expandtree(node)"></i>
                        <i v-if="!node.expanded" class="iconfont iconzhankai" @click.stop="expandtree(node)"></i>
                        <span class="node-name">
                            {{ data.name }}
                            <!-- {{ data.no_audit_count }} -->
                            <span v-if="data.no_audit_count>0" class="popNums">
                                <span class="name">{{ data.no_audit_count }}</span>
                            </span>
                        </span>
                    </div>
                </span>
            </el-tree>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
export default {
    components: {},
    props: {
        refreshData: {
            type: [Object],
        },
    },
    data() {
        return {
            treeData: [],
            defaultProps: {
                children: 'children',
                label: 'name',
            },
            currentNode: {},
            defaultKey: [],
            reportedData: [],
            currentNodeKey: '',
        };
    },
    watch: {
        refreshData: function () {
            const _this = this;
            setTimeout(function () {
                _this.updateTree();
            }, 500);
        },
    },
    computed: {},
    methods: {
        // 获取树结构数据
        async getTree() {
            const res = await this.$axios.get('/interfaceApi/report/instance/summtree/yyqk/1');
            if (res) {
                res.map(item => {
                    item.id = item.year + '';
                    if (item.children && item.children.length > 0) {
                        item.children.map(v => {
                            v.id = item.year + '' + v.month;
                        });
                    }
                    return item;
                });
                this.treeData = res;
                const data = res[0].children[0];
                const result = await this.$axios.get('/interfaceApi/report/instance/summtree/yyqk/' + data.year + '/' + data.month + '/1');
                if (result && result.length > 0) {
                    const result1 = this.addId(result);
                    this.$nextTick(() => {
                        this.$refs.tree.updateKeyChildren(data.id, result1);
                        this.getDefaultKeys(result1);
                        if (this.reportedData.length > 0) {
                            this.$emit('update-month', this.reportedData[0]);
                            this.currentNodeKey = this.reportedData[0].id;
                            const nodekey = this.currentNodeKey;
                            this.$nextTick(() => {
                                this.$refs.tree.setCurrentKey(nodekey);
                            });
                        } else {
                            this.$emit('no-default');
                        }
                    });
                }
            }
        },
        async updateTree() {
            const res = await this.$axios.get('/interfaceApi/report/instance/summtree/yyqk/1');
            if (res) {
                res.map(item => {
                    item.id = item.year + '';
                    if (item.children && item.children.length > 0) {
                        item.children.map(v => {
                            v.id = item.year + '' + v.month;
                        });
                    }
                    return item;
                });
                this.treeData = res;
                // eslint-disable-next-line max-len
                const result = await this.$axios.get('/interfaceApi/report/instance/summtree/yyqk/' + this.refreshData.year + '/' + this.refreshData.month + '/1');
                if (result && result.length > 0) {
                    const result1 = this.addId(result);
                    this.$nextTick(() => {
                        const id = this.refreshData.year + '' + this.refreshData.month;
                        this.$refs.tree.updateKeyChildren(id, result1);
                        this.defaultKey = [];
                        this.defaultKey.push(this.refreshData.id);
                        this.currentNodeKey = this.refreshData.id;
                        const nodekey = this.currentNodeKey;
                        this.$nextTick(() => {
                            this.$refs.tree.setCurrentKey(nodekey);
                        });
                    });
                }
            }
        },
        addId(n) {
            const _this = this;
            n.map(item => {
                item.id = item.treeid + item.year + '' + item.month;
                if (item.children && item.children.length > 0) {
                    item.children = _this.addId(item.children);
                }
            });
            return n;
        },
        getDefaultKeys(n) {
            const _this = this;
            n.map(item => {
                if (item.report_id) {
                    this.defaultKey.push(item.id);
                    this.reportedData.push(item);
                }
                if (item.children && item.children.length > 0) {
                    _this.getDefaultKeys(item.children);
                }
            });
        },
        // 加载标签数据
        async handleNodeClick(data) {
            if (data.datatype === 2) {
                if (data.children === null) {
                    const result = await this.$axios.get('/interfaceApi/report/instance/summtree/yyqk/' + data.year + '/' + data.month + '/1');
                    if (result && result.length > 0) {
                        const result1 = this.addId(result);
                        this.$nextTick(() => {
                            this.$refs.tree.updateKeyChildren(data.id, result1);
                            this.defaultKey = [];
                            this.defaultKey.push(data.id);
                        });
                    }
                }

            }
            if (data.datatype === 3) {
                this.currentNode = data;
                if (this.currentNode.report_id) {
                    this.$emit('update-month', this.currentNode);
                }
                if (this.currentNode.report_id === null && this.currentNode.children.length <= 0) {
                    this.$message.warning('当前未报');
                }
            }
        },
        async expandtree(node) {
            node.expanded = !node.expanded;
            if (node.data.datatype) {
                if (node.data.children === null) {
                    const result = await this.$axios.get('/interfaceApi/report/instance/summtree/yyqk/'
                    + node.data.year + '/' + node.data.month + '/1');
                    if (result && result.length > 0) {
                        const result1 = this.addId(result);
                        this.$nextTick(() => {
                            this.$refs.tree.updateKeyChildren(node.data.id, result1);
                            this.defaultKey = [];
                            this.defaultKey.push(node.data.id);
                        });
                    }
                }
            }
        },
        handleNodeCollapse(data, node) {
            node.expanded = !node.expanded;
        },
        // 添加报表
        addReport() {
            const params = {
                business_code: 'yyqk',
                report_year: this.currentNode.report_year,
                report_month: this.currentNode.report_month,
                report_state: this.currentNode.report_state,
            };
            this.$axios
                .post('/interfaceApi/report/instance/add', params)
                .then(res => {
                    if (res) {
                        this.currentNode = res;
                        this.getTree();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取租户ID
        getTenantId() {
            const TenantId = util.jwtToken('TenantId');
            return TenantId;
        },
    },
    created() {
        this.getTree();
    },
    mounted() {},
};
</script>
<style>
.report-slider-con{
    height:100%;
}
.report-slider-con .search-con{
    padding:.15rem;
    height:.62rem;
}
/* .tree{
    overflow-y: hidden;
    overflow-x: scroll;
    width:80px;
    height: 500px;
} */
.report-slider-con .el-tree {
    min-width: 100%;
    display:inline-block !important;
}
.report-slider-con .tree-con{
    height:calc(100% - .62rem);
    overflow-y:auto;
    overflow-x: scroll;
}
.report-slider-con .tree-con .el-icon-caret-right:before{
    display: none!important;
}
.report-slider-con .tree-con .iconfont{
    font-size: .16rem;
    margin-right: .1rem;
}
.report-slider-con .tree-con .iconshouqi,.iconzhankai{
    color: #5588f1;
}
.report-slider-con .tree-con .el-tree-node__content{
    height: .32rem;
    line-height: .32rem;
    font-size: .16rem;
}
.report-slider-con .tree-con .blue{
    color: #5588f1;
}
.report-slider-con .tree-con .red{
    color: #eb656f;
}
.report-slider-con .tree-con .green{
    color: #21bdcc;
}
.report-slider-con .tree-con .iconlingxing{
    font-size: .1rem;
}
.report-slider-con .tree-con .slot-t-node{
    width: 100%;
}
.report-slider-con .tree-con .leaf-node{
    margin-left: .1rem;
    position: relative;
}
.report-slider-con .tree-con .leaf-node .status{
    /* position: absolute;
    right: .3rem; */
    margin-left: .2rem;
    margin-right: .1rem;
}
.report-slider-con .tree-con .el-tree-node.is-expanded{
    background: #f6fbfd;
}
.report-slider-con .tree-con .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content{
    background: #ecf1f5;
}
.report-slider-con .node-name{
    position: relative;
}
.report-slider-con .node-name .popNums{
    position: absolute;
    right:-.15rem;
    top:-.08rem;
    /* background: #FB4853; */
    color: #fff;
    font-size: .12rem;
    height: 16px;
    line-height: 16px;
}
.report-slider-con .node-name .popNums .name{
    padding:0;
    height: 16px;
    background: #FB4853;
}
.report-slider-con .node-name .popNums:before{
    content: '';
    position:absolute; /*clip 属性剪裁绝对定位元素。也就是说，只有 position:absolute 的时候才是生效的。*/
    width: 16px;
    height: 16px;
    border-radius: 50%;
    clip: rect(0 8px 16px 0); /*唯一合法的形状值是：rect (top, right, bottom, left)*/
    left: -8px;
    background: #FB4853;
}
.report-slider-con .node-name .popNums:after{
    content: '';
    position:absolute; /*clip 属性剪裁绝对定位元素。也就是说，只有 position:absolute 的时候才是生效的。*/
    width: 16px;
    height: 16px;
    border-radius: 50%;
    clip: rect(0 8px 16px 0); /*唯一合法的形状值是：rect (top, right, bottom, left)*/
    right: -8px;
    background: #FB4853;
    clip: rect(0 16px 16px 8px); /*唯一合法的形状值是：rect (top, right, bottom, left)*/

}
</style>