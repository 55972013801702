<template>
    <el-container class="el-height approval-con">
        <div class="fl expend" @click="closeSlider=false" v-if="closeSlider"></div>
        <el-aside class="slider" :class="{'closedSlider': closeSlider}">
            <slider @update-month="updateMonth" @no-default="noData" :refresh-data="refreshData"></slider>
            <div class="close" @click="closeSlider=true" v-if="!closeSlider"></div>
        </el-aside>
        <el-container class="main">
            <el-header class="header clearfix">
                <span class="el_left title">中国联合水泥商混运营情况分析——产销概况</span>
                <div class="el_right btn-group">
                    <el-button
                        class="btn-item"
                        type="primary"
                        size="mini"
                        @click="handelBack"
                        v-if="fillInfo.report_state!=='3'"
                    >
                        退回
                    </el-button>
                    <el-button
                        class="btn-item"
                        type="primary"
                        size="mini"
                        @click="handelPass"
                        v-if="fillInfo.report_state!=='3'"
                    >
                        通过
                        <!-- v-if="fillInfo.report_state!=='3'" -->
                    </el-button>
                </div>
            </el-header>
            <el-main class="main-bottom">
                <div v-show="defaultInfo" class="no-data"></div>
                <div v-show="!defaultInfo" class="el_height">
                    <el-row :gutter="20" class="row">
                        <el-col class="col-2">
                            <div class="grid-content">
                                <div class="el_left tit">
                                    <span class="iconfont iconjindu progress"></span>
                                    <label>填报进度</label>
                                </div>
                                <div class="el_right status progress">
                                    {{ fillInfo.fillin_count }}
                                </div>
                            </div>
                        </el-col>
                        <el-col class="col-2">
                            <div class="grid-content">
                                <div class="el_left tit">
                                    <span class="iconfont iconshijian time font18"></span>
                                    <label>填报时间</label>
                                </div>
                                <div class="el_right status time" :title="fillInfo.created">
                                    {{ fillInfo.created }}
                                </div>
                            </div>
                        </el-col>
                        <el-col class="col-2">
                            <div class="grid-content">
                                <div class="el_left tit">
                                    <span class="iconfont iconyewuyuan reporter font18"></span>
                                    <label>上报人</label>
                                </div>
                                <div class="el_right status reporter" :title="fillInfo.report_username">
                                    {{ fillInfo.report_username }}
                                </div>
                            </div>
                        </el-col>
                        <el-col class="col-2">
                            <div class="grid-content">
                                <div class="el_left tit">
                                    <span class="iconfont iconzhuangtai state font18"></span>
                                    <label>审批状态</label>
                                </div>
                                <div class="el_right status state" v-if="fillInfo.report_state==='0'">
                                    未上报
                                </div>
                                <div class="el_right status state" v-else-if="fillInfo.report_state==='1'">
                                    已报未审
                                </div>
                                <div class="el_right status state" v-else-if="fillInfo.report_state==='2'">
                                    退回
                                </div>
                                <div class="el_right status state" v-else-if="fillInfo.report_state==='3'">
                                    已审
                                </div>
                            </div>
                        </el-col>
                        <el-col class="col-2">
                            <div class="grid-content">
                                <div class="el_left tit">
                                    <span class="iconfont iconqiepian options font18"></span>
                                    <label>审批意见</label>
                                </div>
                                <div class="el_right status options" :title="fillInfo.approve_opinion">
                                    {{ fillInfo.approve_opinion }}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="report-forms">
                        <el-container class="el_height container">
                            <el-aside class="slider">
                                <typeList :type-list="typeListData" @changeTable="handelChangeType" :reset-index="resetId"></typeList>
                            </el-aside>
                            <el-main class="edit-table" ref="tableWrapper" id="tableWrapper">
                                <!-- <editTable :concretes="concretes" :mortars="mortars" :height="tableHeight"></editTable> -->
                                <component
                                    :is="zjName"
                                    :report-month="reportMonth"
                                    :type="'approval'"
                                    ref="table"
                                    :load-obj="loading"
                                    v-if="showTable"
                                ></component>
                            </el-main>
                        </el-container>
                    </div>
                </div>
            </el-main>
        </el-container>
        <el-dialog
            title="请输入意见"
            :visible.sync="optionDialog"
            :close-on-click-modal="false"
            width="30%"
            height="30%"
            center
            class="option-dialog"
        >
            <div>
                <el-input type="textarea" v-model="opinionText"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="optionDialog = false">取 消</el-button>
                <el-button type="primary" @click="handlePassDialog">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="请输入意见"
            :visible.sync="backOptionDialog"
            :close-on-click-modal="false"
            width="30%"
            height="30%"
            center
            class="option-dialog"
        >
            <div>
                <el-input type="textarea" v-model="backOpinionText"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="backOptionDialog = false">取 消</el-button>
                <el-button type="primary" @click="handleBackDialog">确 定</el-button>
            </span>
        </el-dialog>
    </el-container>
</template>

<script>
import slider from './slider';
import typeList from './typeList';
export default {
    components: { slider, typeList },
    props: {},
    data() {
        return {
            zjResData: {
                total: {},
                concretes: {},
                mortars: {},
            },
            typeListData: [],
            componentArr: [
                { componentName: 'productSaleTable' },
                { componentName: 'productFlowTable' },
                { componentName: 'moneyBackTable' },
                { componentName: 'assetUseTable' },
                { componentName: 'materialPurchaseTable' },
                { componentName: 'stockTable' },
                { componentName: 'payableTable' },
                { componentName: 'costsTable' },
            ],
            panelObj: { componentName: 'productSaleTable' },
            type: '',
            reportMonth: {},
            fillInfo: {},
            oldData: {},
            loading: null,
            showTable: false,
            defaultInfo: false,
            resetId: '',
            refreshData: {},
            optionDialog: false,
            opinionText: '',
            backOptionDialog: false,
            backOpinionText: '',
            closeSlider: false, // 收起树结构
        };
    },
    watch: {},
    computed: {
        // 按类型动态加载表格
        zjName: function () {
            let t_url = '';
            t_url = this.panelObj.componentName;
            const myComponent = () => import(`./../reportApprovaltable/${t_url}.vue`);
            return myComponent;
        },
    },
    methods: {
        // 保存
        handelSave() {
            this.$refs.table.save();
        },
        // 退回
        handelBack() {
            this.backOpinionText = '';
            this.backOptionDialog = true;
        },
        handleBackDialog() {
            const params = {
                process_id: 'string',
                report_id: this.reportMonth.report_id,
                opinion: this.backOpinionText,
            };
            this.$axios
                .put('/interfaceApi/report/instance/back', params)
                .then(() => {
                    this.$message.success('退回成功');
                    this.getFillInfo();
                    this.refreshData = this.reportMonth;
                    this.backOpinionText = '';
                    this.backOptionDialog = false;
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 通过
        handelPass() {
            this.opinionText = '';
            this.optionDialog = true;
        },
        handlePassDialog() {
            const params = {
                process_id: 'string',
                report_id: this.reportMonth.report_id,
                opinion: this.opinionText,
            };
            this.$axios
                .put('/interfaceApi/report/instance/auth', params)
                .then(() => {
                    this.$message.success('审核成功');
                    this.getFillInfo();
                    this.refreshData = this.reportMonth;
                    this.opinionText = '';
                    this.optionDialog = false;
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 切换类型
        handelChangeType(index) {
            this.panelObj = this.typeListData[index];
            // this.showLoading();
        },
        // 左侧树切换
        updateMonth(data) {
            this.reportMonth = data;
            this.getFillInfo();
            this.defaultInfo = false;
            this.resetId = this.reportMonth.report_id;
        },
        // 获取填报详情
        getFillInfo() {
            if (this.reportMonth.report_id !== null) {
                this.$axios
                    .get('/interfaceApi/report/instance/fillinfo/' + this.reportMonth.report_id)
                    .then(res => {
                        if (res) {
                            res.created = res.created.substring(0, 10);
                            this.fillInfo = res;
                            this.typeListData = this.fillInfo.infos.map((item, index) => {
                                const json = { ...item, ...this.componentArr[index] };
                                return json;
                            });
                            this.panelObj = this.typeListData[0];
                            this.showTable = true;
                        }
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 显示加载层
        showLoading() {
            this.loading = this.$loading({
                target: '#tableWrapper',
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.8)',
            });
        },
        // 当前年当前月无上报数据
        noData() {
            this.defaultInfo = true;
        },
    },
    created() {},
};
</script>
<style lang="stylus">
.approval-con
    position relative
    .option-dialog
        .el-dialog
            height 350px
            textarea
                height 150px
    .expend
        position absolute
        top 50%
        left 0
        width 14px
        height 24px
        background url(./../images/icon2_normal.png) no-repeat
        background-size 100% 100%
        cursor pointer
        &:hover
            background url(./../images/icon2_highlight.png) no-repeat
            background-size 100% 100%
    .slider
        width 25%!important
        background #fff
        height  100%
        overflow hidden
        position relative
        transition width 0.8s
        -moz-transition width 0.8s /* Firefox 4 */
        -webkit-transition width 0.8s /* Safari 和 Chrome */
        -o-transition width 0.8s /* Opera */
        .close
            position absolute
            top 50%
            right 0
            width 14px
            height 24px
            background url(./../images/icon1_normal.png) no-repeat
            background-size 100% 100%
            cursor pointer
            &:hover
                background url(./../images/icon1_highlight.png) no-repeat
                background-size 100% 100%
    .closedSlider
        width 0!important
    .main
        margin-left .1rem
        .header
            background #fff
            height .46rem!important
            .title
                line-height .46rem
            .btn-group
                margin-top .08rem
                .btn-item
                    font-size .14rem
        .main-bottom
            background #fff
            margin-top .1rem
            padding .1rem
            padding-right 0
            overflow hidden
            height calc(100% - .57rem)
            .no-data
                width 100%
                height 100%
                background url('./../../../../assets/images/bbgl/no_data.png') no-repeat center center
            .row
                width 100%
                .col-2
                    width 20%
                .grid-content
                    height .46rem
                    border 1px solid #d7d7d7
                    .progress
                        color #5588f1
                    .time
                        color #EB656F
                    .state
                        color #21BDCC
                    .options
                        color #fa8436
                        width 1.2rem
                        text-align right
                        white-space nowrap
                        overflow hidden
                        text-overflow ellipsis
                    .reporter
                        color #8B6BEE
                        width 1.2rem
                        text-align right
                        white-space nowrap
                        overflow hidden
                        text-overflow ellipsis
                    .tit
                        line-height .45rem
                        padding-left .12rem
                        width 1.2rem
                        font-size .16rem
                        white-space nowrap
                        overflow hidden
                        text-overflow ellipsis
                        .iconfont
                            font-size .16rem
                            margin-right .1rem
                        .font18
                            font-size .18rem
                    .status
                        width calc(100% - 1.2rem)
                        font-size .16rem
                        line-height .45rem
                        padding-right .12rem
                        text-align right
                        white-space nowrap
                        overflow hidden
                        text-overflow ellipsis
            .report-forms
                height calc(100% - 0.6rem)
                width calc(100% - 0.2rem)
                margin-top 0.1rem
                .container
                    overflow-y auto
                    overflow-x hidden
                    position relative
                    .slider
                        width 1.77rem!important
                        height auto
                        overflow visible
                        position absolute
                    .edit-table
                        padding 0
                        overflow: visible;
                        margin-left 1.77rem
                        .wrapper
                            height auto
                            .custom-table
                                border 1px solid #EBEEF5
                                border-spacing 0px
                                border-collapse collapse
                                width 100%
                                .classify
                                    width:.2rem;
                                    padding: 0 .1rem;
                                .el-input__inner
                                    width calc(100% - .2rem)
                                    height .32rem
                                    line-height .32rem
                                    margin: 0.1rem;
                                    padding .1rem
                                    text-align center
                                    font-size .14rem
                                td,th
                                    border 1px solid #EBEEF5
                                    border-collapse collapse
                                    height .38rem
                                    line-height .38rem
                                    text-align center
                                    font-size: .14rem;
                                    .custom-table
                                        border none
                                .custom-th
                                    // background #F5F7FA
                                    color #022782
                                .custom-th-bg
                                    background #F5F7FA
                                .custom-th-odd
                                    background #FDF3F4
                                    color #EB656F
                                .custom-th-even
                                    background #EEF3FE
                                    color #5588F1
                                .border-top-none
                                    border-top: 0;
                                    border-left: 0;
</style>