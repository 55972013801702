<template>
    <div class="type-list">
        <ul>
            <li
                :class="{'filled':item.state === 1,'notfilled':item.state === 0, 'active': currentIndex === index,}"
                v-for="(item, index) in typeList"
                :key="index"
                @click="changeType(index)"
            >
                <div class="con">
                    <span class="status" v-if="item.state === 1">已填</span>
                    <span class="status" v-else>未填</span>
                    <span class="tit">{{ item.name }}</span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        typeList: {
            type: Array,
        },
        resetIndex: {
            type: [String],
        },
    },
    data() {
        return {
            currentIndex: 0,
        };
    },
    watch: {
        resetIndex() {
            this.currentIndex = 0;
        },
    },
    computed: {},
    methods: {
        changeType(index) {
            this.currentIndex = index;
            this.$emit('changeTable', index);
        },
    },
    created() {
        this.$emit('changeTable', 0);
    },
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.type-list
    li
        position relative
        .con
            width 1.64rem
            height .56rem
            border 1px solid #D7D7D7
            text-align center
            position relative
            margin-bottom .2rem
            overflow hidden
            cursor pointer
            .tit
                line-height .56rem
                font-size .16rem
            .status
                width .56rem
                height .56rem
                position absolute
                left -.28rem
                top -.28rem
                transform rotate(-45deg)
                -webkit-transform rotate(-45deg)
                -moz-transform rotate(-45deg)
                line-height .92rem
                font-size .12rem
                color #fff
            .filled
                background #5588F1
    li.filled.active
        background url('./../../../../assets/images/bbgl/frame_blue.png') no-repeat
        background-size 100% 100%
        .con
            // border 2px solid #5588F1
            border none
            color #5588f1
        // &:after
        //         content " "
        //         width .14rem
        //         height .2rem
        //         position absolute
        //         right: .02rem;
        //         top .18rem
        //         background url('./../../../../assets/images/bbgl/arrow_blue.png')
        //         background-size: 100% 100%;
    li.notfilled.active
        background url('./../../../../assets/images/bbgl/frame_red.png') no-repeat
        background-size 100% 100%
        .con
            border none
            color #EB656F
        // &:after
        //         content " "
        //         width .14rem
        //         height .2rem
        //         position absolute
        //         right: .02rem;
        //         top .18rem
        //         background url('./../../../../assets/images/bbgl/arrow_red.png')
        //         background-size: 100% 100%;
    li.filled
        .status
            background #5588F1
    li.notfilled
        .status
            background #EB656F

</style>